.default-theme {
    --primary-color: #FFFFFF;
    --secondary-color: #91d8ff;
    --soft-color: grey;
    --soft-bkg: #014F9A;
    --disabled-color: #003e77;
}

.radio1-theme, .radio1rock-theme {
    --primary-color: #EB633D;
    --secondary-color: #3b3b3b;
    --soft-color: grey;
    --soft-bkg: #222222;
    --disabled-color: #0f0f0f;
}

.city-theme {
    --primary-color: #FFFFFF;
    --secondary-color: #63b8f8;
    --soft-color: grey;
    --soft-bkg: #2B9CF5;
    --disabled-color: #158be6;
}

.radioveronika-theme {
    --primary-color: #EE1F29;
    --secondary-color: #b6b6b6;
    --soft-color: grey;
    --soft-bkg: #D6D5D5;
    --disabled-color: #9e9e9e;
}

.bgradio-theme {
    --primary-color: #ffb12a;
    --secondary-color: #474747;
    --soft-color: grey;
    --soft-bkg: #2A2B2D;
    --disabled-color: #131414;
}

.radionova-theme {
    --primary-color: #d122ae;
    --secondary-color: #282929;
    --soft-color: grey;
    --soft-bkg: #171A1B;
    --disabled-color: #020202;
}

.radioenergy-theme {
    --primary-color: #E20A1B;
    --secondary-color: #dddddd;
    --soft-color: grey;
    --soft-bkg: #FFFFFF;
    --disabled-color: #d1d1d1;
}
