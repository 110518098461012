.rangeslider {
    position: relative;
    touch-action: none;
    height: 6px;
    background: var(--secondary-color);
}

.rangeslider__fill {
    height: 100%;
    background-color: var(--primary-color);
    border-radius: 2px;
    top: 0;
    height: 6px;
}

.rangeslider__handle {
    width: 9px;
    height: 9px;
    border-radius: 2px;
    top: 50%;
    transform: translate3d(-50%, -50%, 0);
    outline: none;
}

.rangeslider .rangeslider__handle
{
    background: var(--soft-bkg);
    border: 1px solid var(--primary-color);
    cursor: pointer;
    display: inline-block;
    position: absolute;
}

.rangeslider__handle-label {
    display: none;
}

.rangeslider__labels {
    display: none;
}