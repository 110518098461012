.rpbg-streams-drawer {
    position: absolute;
    top: 51px;
    background: var(--soft-bkg);
    left: 236px;
    z-index: 9999;
    box-shadow: 0px 0px 1px 0px var(--primary-color);
    min-width: 65px;
}

.rpbg-streams-drawer ul {
    margin: 0px;
    padding: 0px;
    font-size: 12px;
    font-family: Helvetica;
    height: auto;
}

.rpbg-streams-drawer ul > li {
    list-style-type: none;
    padding: 3px;
    cursor: pointer;
    color: var(--primary-color);
    line-height: 12px;
    font-size: 12px;
    display: block;
    text-align: left;
}

.rpbg-streams-drawer ul > li:hover {
    background: var(--secondary-color);
}