.rpbg-radioplayer {
    height: 54px;
    width: 240px;
    display: block;
    position: relative;
    border-radius: 5px;
    background: var(--soft-bkg);
    border: 1px solid var(--primary-color);
    margin: auto;
    box-sizing: content-box;
}

.rpbg-inner-radioplayer {
    overflow: hidden;
}

.rpbg-radioplayer-button {
    width: 54px;
    height: 54px;
    border-radius: 5px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    border: none;
    border-right: 1px solid var(--primary-color);
    font-size: 24px;
    left: 0px;
    top: 0px;
    outline: none;
    background-color: var(--soft-bkg);
    position: absolute;
    font-family: Arial;
}

.rpbg-radioplayer-button:disabled
{
    background-color: var(--disabled-color);
}

.rpbg-button-play {
    top: 14px;
    left: 18px;
    position: absolute;
    color: var(--primary-color);
}

.rpbg-button-stop {
    top: 9px;
    left: 19px;
    font-size: 28px;
    position: absolute;
    color: var(--primary-color);
}

.rpbg-button-buffering {
    left: 1px;
    top: 0px;
    position: absolute;
    color: var(--primary-color);
}

.rpbg-button-buffering-icon {
    position: absolute;
    top: 18px;
    left: 19px;
    color: var(--quaternary-color);
    animation-delay: .66s;
    font-size: 14px;
}

/* Target Safari only */
@media not all and (min-resolution:.001dpcm)
{   @supports (-webkit-appearance:none) {
        .rpbg-button-play {
            top: 13px;
        }
        .rpbg-button-stop {
            top: 10px;
            font-size: 26px;
            left: 19px;
        }
    }
}
/* Target Firefox only */
@-moz-document url-prefix() {
    .rpbg-button-play {
        top: 13px;
    }

    .rpbg-button-stop {
        top: 10px;
        font-size: 26px;
        left: 19px;
    }

    .rpbg-button-buffering-icon {
        top: 6px;
        left: 18px;
        font-size: 24px;
    }
}

@keyframes flickerAnimation {
    0%   { opacity:0; transform: scale(1, 1); }
    50%  { opacity:0.3; transform: scale(1.4, 1.4); }
    100% { opacity:0; transform: scale(1.8, 1.8); }
}
@-moz-keyframes flickerAnimation {
    0%   { opacity:0; transform: scale(1, 1); }
    50%  { opacity:0.3; transform: scale(1.4, 1.4); }
    100% { opacity:0; transform: scale(1.8, 1.8); }
}
@-webkit-keyframes flickerAnimation {
    0%   { opacity:0; transform: scale(1, 1); }
    50%  { opacity:0.3; transform: scale(1.4, 1.4); }
    100% { opacity:0; transform: scale(1.8, 1.8); }
}

.rpbg-button-buffering > div {
    animation-timing-function: linear;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    -webkit-animation: flickerAnimation 2s infinite;
    -moz-animation: flickerAnimation 2s infinite;
    animation: flickerAnimation 2s infinite;
    opacity: 0;
}

.rpbg-metadata-current-wrap {
    display: block;
    position: absolute;
    left: 57px;
    top: 0px;
}

.rpbg-metadata-current-artwork {
    width: 40px;
    height: 40px;
    background-color: var(--disabled-color);
}

.rpbg-metadata-current-artwork > img {
    width: 100%;
    height: auto;
    box-shadow: 0px 0px 1px var(--soft-bkg);
}

.rpbg-metadata-current-info-wrap {
    height: 39px;
    width: 139px;
    position: absolute;
    left: 40px;
    top: 0px;
    border-bottom: 1px solid var(--primary-color);
    padding-left: 4px;
    font-family: Helvetica;
    color: var(--primary-color);
}

.rpbg-metadata-current-info-wrap p {
    font-family: Helvetica;
    color: var(--primary-color);
    font-weight: 500;
}

.rpbg-metadata-current-title {
    width: 100%;
    font-size: 12px;
    font-weight: 500;
    line-height: 26px;
    height: 24px;
    text-align: left;
}

.rpbg-metadata-current-artist {
    width: 100%;
    font-size: 9px;
    font-weight: 400;
    line-height: 12px;
    height: 16px;
    text-align: left;
}

.rpbg-metadata-current-title > p, .rpbg-metadata-current-artist > p {
    text-overflow: ellipsis;
    display: block;
    width: 135px;
    margin: 0px;
    white-space: nowrap;
    overflow: hidden;
}

.rpbg-volume-bar-wrap{
    width:169px;
    height:10px;
    position: absolute;
    top: 43px;
    left: 57px;
    margin-top: 1px;
}

.rpbg-streams-icon {
    width: 11px;
    height: 14px;
    font-size: 14px;
    color: var(--primary-color);
    position: absolute;
    top: 40px;
    right: 0px;
    font-weight: 800;
    line-height: 13px;
    cursor: pointer;
}